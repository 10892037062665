/**
 * Attempts to safely parse a JSON string.
 * If a "Bad control character" error occurs, it fixes unescaped newlines/carriage returns inside string literals.
 * @param {string} jsonStr - The JSON string to parse.
 * @returns {any} - The parsed JSON value.
 */
function safeJSONParse(jsonStr, opt = {}) {
  try {
    return JSON.parse(jsonStr);
  } catch (err) {
    if (
      err instanceof SyntaxError &&
      err.message.includes("Bad control character")
    ) {
      // Attempt to fix the error by escaping newline and carriage return characters inside string literals.
      const fixedStr = fixNewlinesInJSON(jsonStr);
      return JSON.parse(fixedStr);
    } else {
      // Rethrow any other errors.
      if (opt.catch) opt.catch(err);
      else throw err;
    }
  }
}

/**
 * Processes a JSON string and escapes any literal newline (and carriage return) characters found inside string literals.
 * This is a basic state-machine parser that tracks whether it is inside a quoted string.
 * @param {string} str - The raw JSON string.
 * @returns {string} - The fixed JSON string with properly escaped control characters.
 */
function fixNewlinesInJSON(str) {
  let inString = false;
  let escaped = false;
  let result = "";

  for (let i = 0; i < str.length; i++) {
    let char = str[i];

    if (inString) {
      if (!escaped && char === '"') {
        inString = false;
      }
      // If we see a newline or carriage return inside a string, escape it.
      if (char === "\n") {
        result += "\\n";
        continue;
      }
      if (char === "\r") {
        result += "\\r";
        continue;
      }
      // Check for escape character.
      if (!escaped && char === "\\") {
        escaped = true;
        result += char;
        continue;
      }
      if (escaped) {
        // Whatever character follows a backslash, we just add and reset the escape flag.
        escaped = false;
        result += char;
        continue;
      }
      result += char;
    } else {
      // Not inside a string literal.
      if (char === '"') {
        inString = true;
      }
      result += char;
    }
  }
  return result;
}

export default safeJSONParse;
