import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import _ from "lodash";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import withRouter from "../../Components/Wrapper/with-router";
import NavBar from "../Common/NavBar";
import api from "../../Services/Api/api";
import SoruceElement from "./SoruceElement";
import Canvas from "./Canvas";
import Hierarchy from "./Hierarchy";
import SaveComponent from "./ActionButtons.js/SaveComponent";
import { ShowPaymentMethodBtn } from "./ActionButtons.js/PaymentMethods";
import { ShowCredentialBtn } from "./ActionButtons.js/ThirdPartyCredentials";
import Logout from "./ActionButtons.js/Logout";
import config from "../../Config";
import ElementProperties from "./Properties/ElementProperties";
import { ShowTriggerBtn } from "../Common/Triggers/Triggers";

import sourceElements from "../../appxolo-engine/modules/source-elements/source-elements";
import _dom from "../../appxolo-engine/modules/dom/Dom";
import { ShowRtcLoggerBtn } from "../Common/RtcLogger";
import { ShowOnlineDeviceLoggerBtn } from "../Common/OnlineDeviceLogger";
import DatabasePanel from "../DatabasePanel/DatabasePanel";
import ChangeLog from "./ChangeLog";
import UserPresence from "./UserPresence";
import { socketService } from "../../Services/Socket/socketListeners";
import { ProjectDetailPopupButton } from "../Project/ProjectDetailPopup";
import CustomSelect from "../../Components/etc/CustomSelect";
import { AddAIComponentsPopup, AddComponentPopup } from "../Project/Screens";
import RichTextData from "./Properties/Common/DataProperties/RichTextData";
import { DatabaseDetails } from "../Databases/DatabaseDetailsScreen";
import CanvasWidthSelector from "./CanvasWidthSelector";
import ChatInterface from "./ChatInterface";
import AIPreviewCanvas from "./AIPreviewCanvas";

const { DomTree, DomTreeNode } = _dom;

export class BuilderInner extends React.Component {
  constructor(props) {
    super(props);
    this.sourceElements = this.prepareSourceElements(sourceElements);
  }

  state = {
    error: null,
    loading: false,
    component: null,
    ts: Date.now(),
    showStylePanel: process.env.REACT_APP_DEVELOPER !== "bis",
  };

  componentDidMount() {
    this.load(true);

    socketService.joinSocketRoom(
      `v1/component/reload/${this.props.componentId}`
    );

    this.componentReloadSocketSubId = socketService.subscribeSocketEvent(
      "v1/component/reload",
      (data) => {
        if (data.component.toString() === this.props.componentId?.toString()) {
          this.load(true);
        }
      }
    );
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.componentId !== this.props.componentId) {
      socketService.leavSocketRoom(
        `v1/component/reload/${this.props.componentId}`
      );

      this.load("screen");

      socketService.joinSocketRoom(
        `v1/component/reload/${this.props.componentId}`
      );
    }
  }

  componentWillUnmount() {
    socketService.leavSocketRoom(
      `v1/component/reload/${this.props.componentId}`
    );

    socketService.unsubscribeSocketEvent(
      "v1/component/reload",
      this.componentReloadSocketSubId
    );
  }

  triggerRenderTimer = null;
  triggerRender() {
    clearTimeout(this.triggerRenderTimer);
    this.triggerRenderTimer = setTimeout(() => {
      this.setState({ ts: Date.now() });
    }, 50);
  }

  prepareSourceElements(sourceElements) {
    return sourceElements.map((sourceElement) => ({
      ...sourceElement,
      data: {
        ...sourceElement.data,
        tabs: (sourceElement?.data?.tabs || []).map((tab) => ({
          ...tab,
          styleData: {},
        })),
      },
    }));
  }

  async load(reload = false) {
    try {
      if (this.state.loading) return;

      if (reload === "screen") {
        this.dom = null;
      } else if (reload) {
        this.dom = null;
        this.props.setScreenState({
          project: null,
          components: null,
        });
      }

      this.setState({ error: null, loading: true });
      const { project, component, components, databases } = await api
        .get("v1/project/builder-data", { component: this.props.componentId })
        .then((x) => {
          console.log(x);
          return x;
        });

      const domTreeNode = new DomTreeNode(
        "ROOT",
        { elementType: "container" },
        component?.data?.dom?.children
      );

      const domTree = new DomTree(domTreeNode, this.triggerRender.bind(this));
      this.dom = domTree;

      this.setState({ loading: false, component });
      this.props.setScreenState({ project, components, databases });
    } catch (e) {
      this.setState({ error: e.message, loading: false });
      toast.error(e.message, {
        hideProgressBar: true,
        pauseOnHover: true,
      });
    }
  }

  getCommonPropsToPass() {
    const {
      props: {
        components,
        setScreenState,
        allBuilderData,
        project,
        databases,
        canvasConfig,
      },
      state: { ts, component },
      dom,
    } = this;

    const builderData = _.pick(allBuilderData, [
      "hoverPosition",
      "hoverIndex",
      "focusedElement",
      "hoveredIndices",
    ]);

    const commonPropsToPass = {
      project,
      dom,
      builderData,
      setBuilderData: (x) => {
        setScreenState(x);
      },
      triggerRender: this.triggerRender.bind(this),
      ts,
      availableFor: "front",
      databases,
      component,
      components,
      canvasConfig,

      aiChatbox: this.state.aiChatbox,
      isselectedAiMessage: this.props.isselectedAiMessage,
      uiPreviewDom: this.props.uiPreviewDom,
    };

    return commonPropsToPass;
  }

  async createComponent(event, form) {
    event.preventDefault();

    try {
      if (this.state.addComponent?.loading) return;
      this.setState({ addComponent: { loading: true, error: null } });

      const fileRes = form.image
        ? await api.media("v1/file", { file: form.image })
        : null;

      const res = await api
        .post("v1/component", {
          name: form.name,
          description: form.description,
          image: fileRes?.file._id,
          project: this.props.project._id,
        })
        .catch(async (e) => {
          if (fileRes?.file?._id)
            await api
              .delete(`v1/file/${fileRes?.file._id}`)
              .catch(console.error);
          throw e;
        });

      this.setState({
        addComponent: { loading: false, error: null },
        visibleModal: null,
      });

      this.props.router.navigate(`/canvas/${res.component._id}`);
    } catch (e) {
      this.setState({
        addComponent: { loading: false, error: e.message },
      });
    }
  }

  async handleUrlChange() {
    try {
      if (this.state.editComponent?.loading) return;
      this.setState({
        editComponent: {
          ...(this.state.editComponent || {}),
          loading: true,
          error: null,
        },
      });

      const { components } = await api.get("v1/component", {
        filter: JSON.stringify({
          where: {
            _id: this.state.component._id,
            project: this.props.project._id,
          },
        }),
      });
      const component = components[0];

      if (component?._id !== this.state.component._id) {
        throw new Error("Error getting the screen data");
      }

      await api.put("v1/component", {
        _id: component._id,
        data: {
          ...(component.data || {}),
          url: this.state.component.data?.url,
        },
      });

      this.setState({
        editComponent: {
          ...(this.state.editComponent || {}),
          urlChanged: false,
          loading: false,
          error: null,
        },
      });
    } catch (e) {
      this.setState({
        editComponent: {
          ...(this.state.editComponent || {}),
          loading: false,
          error: e.message,
        },
      });
      toast.error(e.message);
    }
  }

  async setSeo() {
    try {
      if (this.state.settingSeo?.loading) return;
      this.setState({
        settingSeo: {
          ...(this.state.settingSeo || {}),
          loading: true,
          error: null,
        },
      });

      const { components } = await api.get("v1/component", {
        filter: JSON.stringify({
          where: {
            _id: this.state.component._id,
            project: this.props.project._id,
          },
        }),
      });
      const component = components[0];

      if (component?._id !== this.state.component._id) {
        throw new Error("Error getting the screen data");
      }

      await api.put("v1/component", {
        _id: component._id,
        data: {
          ...(component.data || {}),
          seoTitle: this.state.component.data?.seoTitle,
          seoDescription: this.state.component.data?.seoDescription,
        },
      });

      this.setState({
        settingSeo: {
          ...(this.state.settingSeo || {}),
          loading: false,
          error: null,
          visible: false,
        },
      });
    } catch (e) {
      this.setState({
        settingSeo: {
          ...(this.state.settingSeo || {}),
          loading: false,
          error: e.message,
        },
      });
      toast.error(e.message);
    }
  }

  async updateProject(updatedProject) {
    try {
      if (this.state.editProject?.loading) return;
      this.setState({
        editProject: {
          ...(this.state.editProject || {}),
          loading: true,
          error: null,
        },
      });

      const { projects } = await api.get("v1/project", {
        filter: JSON.stringify({
          where: {
            _id: this.props.project._id,
          },
        }),
      });
      const project = projects[0];

      if (project?._id !== this.props.project._id) {
        throw new Error("Error getting the screen data");
      }

      await api.put("v1/project", {
        ...updatedProject,

        _id: project._id,
        data: {
          ...(project.data || {}),
          ...(updatedProject.data || {}),
        },
      });

      this.setState({
        editProject: {
          ...(this.state.editProject || {}),
          loading: false,
          error: null,
        },
      });
    } catch (e) {
      this.setState({
        editProject: {
          ...(this.state.editProject || {}),
          loading: false,
          error: e.message,
        },
      });
      toast.error(e.message);
    }
  }

  async setAsHomePage() {
    try {
      if (this.state.settingHomePage?.loading) return;
      this.setState({
        settingHomePage: {
          ...(this.state.settingHomePage || {}),
          loading: true,
          error: null,
        },
      });

      await this.updateProject({
        data: { homePage: this.state.component._id },
      });

      this.setState({
        settingHomePage: {
          ...(this.state.settingHomePage || {}),
          homePageSet: true,
          loading: false,
          error: null,
        },
      });
    } catch (e) {
      this.setState({
        settingHomePage: {
          ...(this.state.settingHomePage || {}),
          loading: false,
          error: e.message,
        },
      });
      toast.error(e.message);
    }
  }

  async deleteComponent(componentId) {
    try {
      await api.delete("v1/component/" + componentId);
    } catch (e) {
      toast.error(e.message);
    }
  }

  screenList() {
    const {
      props: { components, project },
      state: { component },
    } = this;

    const deleteComponent = async (component) => {
      try {
        await this.deleteComponent(component._id);
        const anotherComponent = components.find(
          (x) => x._id !== component._id
        );

        if (anotherComponent)
          this.props.router.navigate(`/canvas/${anotherComponent?._id}`);
      } catch (e) {
        console.log(e);
      }
    };

    const isWebApp =
      1 || !project.data?.platform || project.data?.platform === "web";

    return (
      <div className="builderPanel two">
        <div className="builderScreens">
          <div className="card-body">
            <div className="builderScreensLabel">Screens</div>

            <div className="builderScreenBox">
              <div className="screenItemGroup">
                <div className="oibLabel">Name</div>

                <CustomSelect
                  onChange={(option) => {
                    if (option.value === null) {
                      this.setState({ visibleModal: "addComponent" });
                    } else {
                      this.props.router.navigate(`/canvas/${option._id}`);
                    }
                  }}
                  value={component?._id || ""}
                  placeholder="Screen Name"
                  options={[
                    { value: null, label: "Add Screen" },
                    ...components?.map((x) => ({
                      ...x,
                      value: x._id,
                      label: x.name,
                    })),
                  ]}
                  classNames={{
                    head: "optionInputIconBox",
                    label: "optionInputIconBoxField",
                    chevron: "optionDatabaseSelectChevron",
                  }}
                />
              </div>

              {isWebApp ? (
                <div className="screenItemGroup urlSet">
                  <div className="oibLabel">URL</div>
                  <div className="screenSelectorItem">
                    <input
                      className="optionInputIconBoxField"
                      placeholder="URL"
                      value={component?.data?.url || ""}
                      onChange={(e) =>
                        this.setState({
                          component: {
                            ...component,
                            data: {
                              ...(component.data || {}),
                              url: e.target.value,
                            },
                          },
                          editComponent: { urlChanged: true },
                        })
                      }
                      disabled={this.state.editComponent?.loading}
                    />

                    {this.state.editComponent?.urlChanged ? (
                      <div
                        onClick={() => this.handleUrlChange()}
                        className="screenApply"
                      >
                        {this.state.editComponent?.loading
                          ? "Loading"
                          : "Apply"}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {(isWebApp && this.state.settingSeo?.visible
                ? [
                    { value: "seoTitle", label: "SEO Title" },
                    { value: "seoDescription", label: "SEO Description" },
                  ]
                : []
              ).map(({ value: key, label }) => (
                <div className="screenItemGroup" key={key}>
                  <div className="oibLabel">{label}</div>

                  <div>
                    <RichTextData
                      {...{
                        key: this.state.component?._id || "blank",
                        project: this.props.project,
                        databases: this.props.databases,
                        dom: null,
                        value: component.data?.[key]?.valueObj,
                        immediateProps: {
                          classNames: {
                            wrapper: "screenSelectorItem rte",
                            editor: "optionInputIconBoxField",
                            pButton: "gray",
                          },
                        },
                        onChange: (valueObj) =>
                          this.setState({
                            component: {
                              ...component,
                              data: {
                                ...(component.data || {}),
                                [key]: {
                                  valueType: "textParts",
                                  valueObj,
                                },
                              },
                            },
                          }),
                      }}
                    />
                  </div>
                </div>
              ))}

              <div className="screenItemGroup">
                <div className="oibLabel">Action</div>
                <div className="screenItemAction">
                  <div
                    className="defaultButton"
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you want to permanently delete the screen "${component.name}"?`
                        )
                      ) {
                        deleteComponent(component);
                      }
                    }}
                    style={{ color: "red" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="red"
                      stroke="red"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6l-1 14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2L5 6"></path>
                      <path d="M10 11v6"></path>
                      <path d="M14 11v6"></path>
                      <path d="M9 6V4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2"></path>
                    </svg>
                  </div>
                  <div
                    className="defaultButton"
                    onClick={() => {
                      if (
                        !(
                          this.props.project?.data?.homePage ===
                            this.state.component._id ||
                          this.state.settingHomePage?.homePageSet
                        )
                      )
                        this.setAsHomePage();
                    }}
                  >
                    <img
                      className="setHomeIco"
                      src={require("../../Assets/img/header/home.png")}
                    />
                    {this.state.settingHomePage?.loading
                      ? "Loading"
                      : this.props.project?.data?.homePage ===
                          this.state.component._id ||
                        this.state.settingHomePage?.homePageSet
                      ? "Home Page"
                      : "Set Home"}
                  </div>

                  {isWebApp ? (
                    this.state.settingSeo?.visible ? (
                      <div className="seoSettingsAction">
                        <div
                          className="defaultButton active"
                          onClick={() => this.setSeo()}
                        >
                          {this.state.settingSeo?.loading ? "Loading" : "Save"}
                        </div>

                        <div
                          className="defaultButton active cancelSeo"
                          onClick={() =>
                            this.setState({
                              settingSeo: {
                                visible: false,
                              },
                            })
                          }
                        >
                          <img
                            className="cancelIco"
                            src={require("../../Assets/img/user/close.png")}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="defaultButton"
                        onClick={() =>
                          this.setState({
                            settingSeo: {
                              ...(this.state.settingSeo || {}),
                              visible: true,
                            },
                          })
                        }
                      >
                        Set SEO
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.visibleModal === "addComponent" ? (
          <AddComponentPopup
            setPopup={(x) =>
              this.setState({ visibleModal: x ? "addComponent" : null })
            }
            handleSubmit={this.createComponent.bind(this)}
            formError={this.state.addComponent?.error}
            loading={this.state.addComponent?.loading}
          />
        ) : this.state.visibleModal === "generate_app" ? (
          <AddAIComponentsPopup
            project={this.props.project}
            data={this.state.modalData}
            close={() =>
              this.setState({
                visibleModal: null,
                modalData: null,
              })
            }
            reload={() => this.load(true)}
          />
        ) : null}
      </div>
    );
  }

  leftPanel() {
    const {
      props: { project, databases },
      dom,
      sourceElements,
    } = this;

    const commonPropsToPass = this.getCommonPropsToPass();

    return (
      <div className="builderPane">
        <div className="builderPanel one">
          <div className="builderTools">
            <div className="builderElementsLabel">
              <div
                onClick={() =>
                  this.setState({ aiChatbox: !this.state.aiChatbox })
                }
                className={this.state.aiChatbox ? "" : " active"}
              >
                Elements
              </div>
              <div
                onClick={() =>
                  this.setState({ aiChatbox: !this.state.aiChatbox })
                }
                className={!this.state.aiChatbox ? "" : " active"}
              >
                AI Mode
              </div>
            </div>
            <div className="builderElements">
              {this.state.aiChatbox
                ? null
                : sourceElements.map((item) => (
                    <SoruceElement
                      {...commonPropsToPass}
                      key={item.elementType}
                      item={item}
                    />
                  ))}
            </div>
          </div>
        </div>

        {this.state.aiChatbox ? (
          <ChatInterface {...commonPropsToPass} />
        ) : (
          <>
            {/* <AIPrompt
              {...commonPropsToPass}
              onAiCompletion={(res) => {
                if (
                  res?.response?.choices?.[0]?.message?.function_call?.name ===
                  "generate_app"
                ) {
                  this.setState({
                    visibleModal: "generate_app",
                    modalData: res,
                  });
                }
              }}
            /> */}

            {this.screenList()}

            {/* <UserPresence {...commonPropsToPass} /> */}

            <div className="builderPanel displayNone">
              <div className="builderActions">
                <div className="card-body">
                  <ShowPaymentMethodBtn
                    filter={{
                      where: { project: project?._id },
                    }}
                  />
                  <ShowCredentialBtn
                    filter={{
                      where: { project: project?._id },
                    }}
                  />
                  <ShowTriggerBtn {...{ project, databases, dom }} />
                  <ShowRtcLoggerBtn {...{ project, databases, dom }} />
                  <ShowOnlineDeviceLoggerBtn {...{ project, databases, dom }} />
                  <Link to={`/database?projectId=${project?._id}`}>
                    <button>Database Viewer</button>
                  </Link>
                  <ProjectDetailPopupButton
                    {...{
                      ...commonPropsToPass,
                      projectId: commonPropsToPass.project?._id,
                    }}
                  />
                  <Logout />
                </div>
              </div>
            </div>

            <div className="builderPanel three">
              <div className="builderHierarchy">
                <Hierarchy {...commonPropsToPass} />
              </div>
            </div>

            {commonPropsToPass?.component?._id ? (
              <div>
                <ChangeLog
                  {...commonPropsToPass}
                  reload={() => {
                    this.load(true).then(this.triggerRender.bind(this));
                  }}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  }

  centerPanel() {
    const commonPropsToPass = this.getCommonPropsToPass();

    return (
      <>
        <div className="builderCanvas">
          {this.state.aiChatbox && this.props.isselectedAiMessage ? (
            <AIPreviewCanvas {...commonPropsToPass} />
          ) : null}
          {commonPropsToPass.dom ? <Canvas {...commonPropsToPass} /> : null}
        </div>
        <div className="builderFooterControls">
          <BuilderDatabaseViewer {...this.props} />
          <CanvasWidthSelector {...this.props} />
        </div>
      </>
    );
  }

  rightPanel() {
    const {
      props: { allBuilderData },
    } = this;

    if (!allBuilderData || !this.dom) return null;

    const builderData = _.pick(allBuilderData, [
      "hoverPosition",
      "hoverIndex",
      "focusedElement",
      "hoveredIndices",
    ]);

    const commonPropsToPass = this.getCommonPropsToPass();

    return (
      <div className="builderOptions">
        <div className="optionsPanel">
          {builderData.focusedElement?.element?.id ? (
            <ElementProperties
              key={builderData.focusedElement?.element?.id}
              focusedElement={builderData.focusedElement}
              {...commonPropsToPass}
            />
          ) : null}
        </div>
      </div>
    );
  }

  headerRight() {
    const {
      props: { project },
      state: { component },
      dom,
    } = this;

    const commonPropsToPass = this.getCommonPropsToPass();

    return (
      <div className="xoloPush">
        {component?._id ? <UserPresence component={component} /> : null}

        <ProjectDetailPopupButton
          {...{
            ...commonPropsToPass,
            projectId: commonPropsToPass.project?._id,
          }}
        />

        <SaveComponent
          component={{
            ...component,
            data: { ...(component?.data || {}), dom: dom?.tree },
          }}
        />
        {/* <div className="xoloStatus">
          <div className="xoloStatusInner"></div>
        </div> */}
        <a
          href={`//${project?.subdomain}.${config.frontDomain}/${
            component?.data?.url || component?._id
          }`}
          target="execute"
        >
          <div className="xoloRepublish">
            <div className="xoloRepublishLabel">Republish</div>
            <div className="xoloRepublishIco"></div>
          </div>
        </a>
      </div>
    );
  }

  headerBottom() {
    const {
      props: { project },
    } = this;

    return (
      <div className="xoloHeaderBottom">
        <div className="xoloHeaderAction">
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <Link className="xoloBionicLabel" to={`/project/${project?._id}`}>
              Screens
            </Link>
          </div>
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <Link
              className="xoloBionicLabel"
              to={`/database?projectId=${project?._id}`}
            >
              Database
            </Link>
          </div>
        </div>
        <div className="xoloPlatform">
          <div className="xoloPlatformItem active">iOS</div>
          <div className="xoloPlatformItem">Android</div>
        </div>
        <div className="xoloHeaderAction">
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <div className="xoloBionicLabel">Templates</div>
          </div>
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <div className="xoloBionicLabel">Admin Panel</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      props: { components, project, databases },
      state: { loading, component },
      dom,
    } = this;

    return (
      <>
        <NavBar
          className="builderscreen"
          activeLink="/projects"
          right={this.headerRight()}
          bottom={this.headerBottom()}
          project={this.props.project}
        />

        {loading && !components ? (
          <center>Loading...</center>
        ) : component && project ? (
          <div className="appxoloBuilder">
            {this.leftPanel()}
            {this.centerPanel()}
            {this.rightPanel()}
          </div>
        ) : null}
      </>
    );
  }
}

const BuilderDatabaseViewer = (props) => {
  const databases = props.databases;
  const [dbName, setDbName] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!dbName) setDbName(databases?.[0]?.name);
  }, [dbName, databases]);

  if (!dbName) return null;

  return (
    <div className="builderDatabaseWrapper">
      <div className="builderDatabaseHeader">
        <div
          className={"databaseAnchorAction " + (visible ? "active" : "")}
          onClick={() => setVisible((prev) => !prev)}
        >
          <div className="daaDecor">
            +
            {/* <img
        className="daaDecorIco"
        src={require("../../Assets/img/options/database/view.png")}
      ></img> */}
          </div>
          <div className="daaLabel">Database</div>
        </div>
      </div>

      <div
        className="builderDatabase"
        style={{
          maxHeight: visible ? "600px" : "0px",
          transition: " all .75s ease",
          overflow: "hidden",
        }}
      >
        <DatabaseDetails
          {...{
            ...props,
            dbName,
            onDbChange: (x) => setDbName(x.name),
            databaseSelector: {
              limit: 5,
            },
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.vState.BUILDER.project,
  databases: state.vState.BUILDER.databases,
  components: state.vState.BUILDER.components,
  allBuilderData: state.vState.BUILDER,
  canvasConfig: state.vState.BUILDER.canvasConfig || {},
  isselectedAiMessage: !!state.vState.AI_CHAT_INTERFACE.selectedAiMessage,
  uiPreviewDom: state.vState.BUILDER.uiPreviewDom,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "BUILDER") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const Builder = DragDropContext(HTML5Backend)(
  connect(mapStateToProps, mapDispatchToProps)(BuilderInner)
);

const BuilderScreen = withRouter((props) => (
  <Builder {...{ ...props.router.params, router: props.router }} />
));

export default BuilderScreen;
