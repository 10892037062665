import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import { Form, Button, Table, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";

import api from "../../Services/Api/api";
import AppModal from "../../Components/Models/app-modal";

export const AddComponentPopup = (props) => {
  const [form, setForm] = useState({ ...(props.component || {}) });

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  const content = (
    <div>
      <div className="errormsg">{props.formError}</div>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          required
          value={form?.name || ""}
          onChange={(e) => {
            updateForm({ name: e.target.value });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter Description"
          value={form?.description || ""}
          onChange={(e) => {
            updateForm({ description: e.target.value });
          }}
        />
      </Form.Group>

      {props.loading ? <div>Loading...</div> : null}
      {/* <Button disabled={props.loading} type="submit">
        Create Component
      </Button>{" "}
      <Button type="button" onClick={() => props.setPopup(false)}>
        Cancel
      </Button> */}
    </div>
  );

  return (
    <AppModal
      {...{
        title: props.title || "Create New Screen",
        visible: true,
        handleClose: () => props.setPopup(false),
        disableBtns: props.loading,
        submitBtnText: props.loading
          ? "Loading"
          : props.submitBtnText || "Create Screen",
        onSubmit: (e) => props.handleSubmit(e, form),
      }}
    >
      {content}
    </AppModal>
  );
};

export const AddAIComponentsPopup = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [screens, setScreens] = useState(props.data?.arguments?.screens || []);

  const addScreen = () => {
    setScreens([...screens, { name: "", description: "" }]);
  };

  const updateScreen = (index, key, value) => {
    const updatedScreens = screens.map((screen, i) =>
      i === index ? { ...screen, [key]: value } : screen
    );
    setScreens(updatedScreens);
  };

  const deleteScreen = (index) => {
    setScreens(screens.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    console.log({ screens });

    try {
      if (loading) return;
      setError(null);
      setLoading(true);

      const res = await api.post("v1/component/many", {
        components: screens
          ?.filter(
            (x, i, arr) =>
              x.name && i === arr.findIndex((y) => y.name === x.name)
          )
          ?.map((x) => _.pick(x, ["name", "description"])),
        project: props.project._id,
      });

      toast.success("Screens are created successfully");

      props.close();
      props.reload()

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  };

  const content = (
    <div>
      {screens.map((screen, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            marginBottom: "15px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "12px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <input
            type="text"
            placeholder="Screen Name"
            value={screen.name}
            onChange={(e) => updateScreen(index, "name", e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #ddd",
              borderRadius: "5px",
            }}
          />
          <textarea
            placeholder="Description"
            value={screen.description}
            onChange={(e) => updateScreen(index, "description", e.target.value)}
            rows="3"
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #ddd",
              borderRadius: "5px",
              resize: "vertical",
            }}
          />
          <button
            onClick={() => deleteScreen(index)}
            style={{
              background: "red",
              color: "white",
              border: "none",
              padding: "6px 12px",
              borderRadius: "5px",
              cursor: "pointer",
              alignSelf: "flex-end",
            }}
          >
            &#10060; Delete
          </button>
        </div>
      ))}
      <button
        onClick={addScreen}
        style={{
          marginTop: "10px",
          padding: "8px 12px",
          background: "green",
          color: "white",
          border: "none",
          cursor: "pointer",
        }}
      >
        Add Screen
      </button>
    </div>
  );

  return (
    <AppModal
      {...{
        title: props.title || "Add New Screens",
        visible: true,
        handleClose: () => props.close(),
        disableBtns: props.loading,
        submitBtnText: props.loading
          ? "Loading"
          : props.submitBtnText || "Submit",
        onSubmit: handleSubmit,
        modalProps: { size: "lg" },
      }}
    >
      {content}
    </AppModal>
  );
};

const AddComponent = (props) => {
  const [popup, setPopup] = useState(false);
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);

  const appendComponent = (component) => {
    props.setComponents(update(props.components, { $push: [component] }));
  };

  const handleSubmit = async (event, form) => {
    event.preventDefault();

    try {
      if (loading) return;

      setLoading(true);
      setFormError(null);

      const fileRes = form.image
        ? await api.media("v1/file", { file: form.image })
        : null;

      const res = await api
        .post("v1/component", {
          name: form.name,
          description: form.description,
          image: fileRes?.file._id,
          project: props.projectId,
        })
        .catch(async (e) => {
          if (fileRes?.file?._id)
            await api
              .delete(`v1/file/${fileRes?.file._id}`)
              .catch(console.error);
          throw e;
        });
      setLoading(false);
      setPopup(false);

      appendComponent(res.component);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
    }
  };

  return (
    <div>
      <div>
        <div onClick={() => setPopup(true)}>Add Screen</div>
      </div>

      {popup ? (
        <AddComponentPopup
          setPopup={setPopup}
          handleSubmit={handleSubmit}
          formError={formError}
          loading={loading}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const ListComponent = (props) => {
  return (
    <div>
      {!props.components ? (
        <div className="noList"></div>
      ) : !props.components?.length ? (
        <div className="noList">Currently you have no screens</div>
      ) : (
        <div style={{ display: "flex", gap: "10px" }}>
          {props.components.map((component) => (
            <div className="box" key={component._id}>
              <div>{component.name}</div>
              <div>{component.description}</div>
              <div>
                <Link key={component._id} to={`/canvas/${component._id}`}>
                  <button variant="success">Open</button>
                </Link>
                <button onClick={() => props.edit(component)} variant="primary">
                  Edit
                </button>
                <button
                  onClick={() => props.delete(component)}
                  variant="danger"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Screens = (props) => {
  const [components, setComponents] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  const projectId = props.projectId;

  const fetchComponents = useCallback(async () => {
    try {
      const { components } = await api.get("v1/component", {
        filter: JSON.stringify({
          where: { project: projectId },
        }),
      });
      setComponents(components);
    } catch (e) {
      console.error(e.message);
    }
  }, [projectId]);

  useEffect(() => {
    fetchComponents();
  }, [fetchComponents]);

  const deleteComponent = async (component) => {
    try {
      const typed = window.prompt(
        "Are you sure you want to delete this screen ? Confirm by typing the screeen name"
      );
      if (typed && typed === component.name) {
        await api.delete(`v1/component/${component._id}`);
        await fetchComponents();
      } else if (typed !== null) {
        window.alert("Name mismatched");
        deleteComponent(component);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const handleEditSubmit = async (event, form) => {
    event.preventDefault();

    try {
      if (loading) return;

      setLoading(true);
      setFormError(null);

      let image = form.image;

      if (form.newImage && form.image) {
        const fileRes = await api.media("v1/file", { file: form.image });
        image = fileRes?.file?._id;
      }

      await api
        .put("v1/component", {
          _id: form._id,
          name: form.name,
          description: form.description,
          image,
        })
        .catch(async (e) => {
          if (form.newImage && image) {
            api.delete(`v1/file/${image}`).catch(console.error);
          }
          throw e;
        });
      setLoading(false);
      setEditModal(null);

      fetchComponents();
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
    }
  };

  return (
    <>
      <div>
        <div className="flex sb">
          <div>Screens</div>
          <AddComponent
            components={components}
            setComponents={setComponents}
            projectId={projectId}
          />
        </div>
        <ListComponent
          components={components}
          setComponents={setComponents}
          edit={(x) => setEditModal(x)}
          delete={deleteComponent}
        />
      </div>

      {editModal ? (
        <AddComponentPopup
          setPopup={setEditModal}
          handleSubmit={handleEditSubmit}
          formError={formError}
          loading={loading}
          component={editModal}
          title="Edit Screen"
          submitBtnText="Submit"
          projectId={projectId}
        />
      ) : null}
    </>
  );
};

export default Screens;
