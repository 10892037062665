import processStyle from "../../appxolo-engine/modules/styling/processStyle";
import styleModule from "../style/style-module";

class AiModule {
  transformStructure(obj) {
    let { id, children, elementType, styles } = obj;

    if (
      !["container", "text", "image", "media", "input", "map"].includes(
        elementType
      )
    )
      return null;

    const transformedChildren = children
      ?.map(this.transformStructure.bind(this))
      ?.filter((x) => x);

    return {
      id,
      value: {
        elementType,
        data: {
          tabs: [
            {
              styleData: {
                default: processStyle.processStyle(styles || {}),
              },
              [`${elementType}Data`]: this.transformElementData(
                obj[`${elementType}Data`],
                elementType
              ),
            },
          ],
        },
      },
      children: transformedChildren || [],
    };
  }

  transformElementData(data, elementType) {
    let result = {};

    if (elementType === "text") {
      result = {
        textParts: this.transformTextParts(data?.textParts),
      };
    } else {
      result = data;
    }

    return result;
  }

  transformTextParts(data = []) {
    let parts = [];
    if (!Array.isArray(data)) parts = [data];
    else parts = data;

    parts = parts.map((part) => {
      const id = part.id || Math.random().toString();
      let valueType = part.valueType || "customText";

      if (valueType === "customText") {
        return {
          id,
          valueType,
          valueObj: {
            customText:
              part?.valueObj?.customText ||
              part?.valueObj?.text ||
              part?.customText ||
              part?.text,
          },
        };
      } else if (valueType === "calculation") {
        let calculation = part.valueObj?.calculation
          ? part.valueObj?.calculation
          : Array.isArray(part.valueObj)
          ? part.valueObj
          : [part.valueObj];

        return {
          id,
          valueType,
          valueObj: {
            calculation: this.processCalculation(calculation),
          },
        };
      } else {
        let calculation = [
          {
            ...part,
          },
        ];

        return {
          id,
          valueType: "calculation",
          valueObj: {
            calculation: this.processCalculation(calculation),
          },
        };
      }
    });

    return parts;
  }

  processCalculation(data) {
    let calculation = data || [];
    if (!Array.isArray(calculation)) calculation = [calculation];

    return calculation;
  }

  processUiMarkup(uiMarkup) {
    return this.transformStructure(uiMarkup);
  }
}

const aiModule = new AiModule();

export default aiModule;
