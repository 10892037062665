import React, {
  useState,
  useEffect,
  useRef,
  Component,
  createRef,
} from "react";
import { toast } from "react-toastify";
import _ from "lodash";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import api from "../../Services/Api/api";
import safeJSONParse from "../../Modules/etc/json-parser";
import _dom from "../../appxolo-engine/modules/dom/Dom";
import Canvas from "./Canvas";
import aiModule from "../../Modules/ai-module/aimodule";

const { DomTree, DomTreeNode } = _dom;

class AIPreviewCanvas extends Component {
  state = {
    itemList: [],
    activeIndex: 0,
    loadId: 0,
  };

  componentDidMount() {
    if (this.props.selectedAiMessage?.content) {
      this.processAiContent(this.props.selectedAiMessage);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedAiMessage?.content !==
      prevProps.selectedAiMessage?.content
    ) {
      this.processAiContent(this.props.selectedAiMessage);
    }
  }

  async processAiContent(msg) {
    try {
      const jsonContent = safeJSONParse(msg?.content, { catch: () => null });

      const command = jsonContent?.command || "conversation";
      const data = jsonContent?.data || {};
      const arrayKey = Object.keys(data).find((key) =>
        Array.isArray(data[key])
      );

      let itemList = arrayKey ? data[arrayKey] : [data];

      itemList = itemList.map((item) => {
        if (item.screenName && item.uiMarkup) {
          return {
            ...item,
            uiMarkup: this.processUiMarkup(item.uiMarkup),
            title: item.uiMarkup?.id,
          };
        } else {
          return item;
        }
      });

      this.setState({ itemList, command, loadId: Math.random() });
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }

  processUiMarkup(uiMarkup) {
    const transformed = aiModule.transformStructure(uiMarkup);
    console.log({ uiMarkup, transformed });
    return transformed;
  }

  render() {
    const { itemList, activeIndex, command, loadId } = this.state;

    return (
      <div style={styles.container}>
        {/* Tab Headers */}
        <div style={styles.tabContainer}>
          {itemList.map((item, index) => (
            <button
              key={index}
              style={index === activeIndex ? styles.activeTab : styles.tab}
              onClick={() => {
                this.setState({ activeIndex: index });
                // this.props.router.navigate(`/canvas/67af8327153e02fc8d000d07`);
              }}
            >
              {item.title}
            </button>
          ))}
        </div>

        {/* Tab Content */}
        <div style={styles.content}>
          <UIPreview
            {...this.props}
            key={activeIndex + loadId}
            item={itemList[activeIndex || 0]}
          />
        </div>

        {/* <div>{JSON.stringify(this.props.selectedAiMessage)}</div> */}
      </div>
    );
  }
}

class UIPreview extends React.Component {
  state = {};

  componentDidMount() {
    const uiMarkup = this.props.item?.uiMarkup;
    if (uiMarkup) {
      const domTreeNode = new DomTreeNode(
        "ROOT",
        { elementType: "container" },
        [uiMarkup]
      );

      const domTree = new DomTree(domTreeNode, this.triggerRender.bind(this));
      this.dom = domTree;
      this.triggerRender();

      this.props.setBuilderData({ uiPreviewDom: domTree });
    }
  }

  triggerRender() {
    this.props.triggerRender();
    // this.setState({ ts: Date.now() });
  }

  render() {
    const {
      props: { item },
    } = this;

    return <>{this.dom && <Canvas {...{ ...this.props, dom: this.dom }} />}</>;
  }
}

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
  },
  tabContainer: {
    display: "flex",
    borderBottom: "2px solid #ddd",
    overflowX: "auto",
  },
  tab: {
    padding: "10px 20px",
    cursor: "pointer",
    backgroundColor: "#f5f5f5",
    border: "none",
    borderBottom: "2px solid transparent",
    flex: 1,
  },
  activeTab: {
    padding: "10px 20px",
    cursor: "pointer",
    backgroundColor: "#fff",
    border: "none",
    borderBottom: "2px solid #007bff",
    fontWeight: "bold",
    flex: 1,
  },
  content: {
    padding: "20px",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    marginTop: "10px",
  },
};

const mapStateToProps = (state) => ({
  project: state.vState.BUILDER.project,
  databases: state.vState.BUILDER.databases,
  components: state.vState.BUILDER.components,
  allBuilderData: state.vState.BUILDER,
  canvasConfig: state.vState.BUILDER.canvasConfig || {},
  session: state.vState.AI_CHAT_INTERFACE.session || [],
  messages: state.vState.AI_CHAT_INTERFACE.messages || [],
  inputValue: state.vState.AI_CHAT_INTERFACE.inputValue || "",
  selectedAiMessage: state.vState.AI_CHAT_INTERFACE.selectedAiMessage,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "AI_CHAT_INTERFACE") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AIPreviewCanvas);
