import React, {
  useState,
  useEffect,
  useRef,
  Component,
  createRef,
} from "react";
import { toast } from "react-toastify";
import _ from "lodash";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import api from "../../Services/Api/api";
import safeJSONParse from "../../Modules/etc/json-parser";
import etcModule from "../../Modules/etc/etc-module";
import aiModule from "../../Modules/ai-module/aimodule";

import _dom from "../../appxolo-engine/modules/dom/Dom";
const { DomTree, DomTreeNode } = _dom;


export class AIPrompt extends Component {
  state = {
    prompt: "",
    loading: false,
    error: null,
  };

  async submit() {
    if (!this.state.prompt || this.state.loading) return;
    try {
      this.setState({ loading: true });
      const res = await api.post("v1/openai/prompt", {
        prompt: this.state.prompt,
      });
      console.log("ai res", res);

      // if (res?.json) {

      //   this.props.dom.addNode("ROOT", json, 0);
      // }

      if (this.props.onAiCompletion) this.props.onAiCompletion(res);
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, error: e.message });
      toast.error(e.message);
    }
  }

  render() {
    return (
      <div style={{ display: "flex" }}>
        <input
          style={{ width: "100%" }}
          value={this.state.prompt}
          onChange={(e) => this.setState({ prompt: e.target.value })}
          placeholder="Perform tasks using AI"
        />
        <button onClick={this.submit.bind(this)}>
          {this.state.loading ? "Loading" : "Submit"}
        </button>
      </div>
    );
  }
}

// ChatInterface.jsx
class ChatInterface extends Component {
  constructor(props) {
    super(props);
    this.messagesEndRef = createRef();
    this.state = {
      error: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });

      if (this.props.session?.project !== this.props.project?._id) {
        this.props.setScreenState({
          messages: [],
          session: null,
          inputValue: "",
        });
      }

      const { session } = await api.post("v1/openai/ai-session/get", {
        project: this.props.project._id,
      });

      console.log({ session });

      this.props.setScreenState({
        session: _.omit(session, ["messages"]),
        messages: session.messages,
      });

      this.setState({ loading: false });
    } catch (e) {
      this.setState({ error: e.message, loading: false });
      console.log(e.message);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messages !== this.props.messages) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleSendMessage = async (event) => {
    event.preventDefault();

    if (
      !this.props.inputValue ||
      !this.props.session?._id ||
      this.state.loading
    )
      return;

    const { inputValue, messages, setScreenState } = this.props;

    if (!inputValue.trim()) return;

    const userMessage = { role: "user", content: inputValue.trim() };

    // Update messages in Redux
    setScreenState({ messages: [...messages, userMessage] });

    // Clear input
    setScreenState({ inputValue: "" });

    // Simulate AI response (replace with actual API call)
    const message = await this.aiResponse(userMessage, messages);

    // Update Redux state with AI response
    if (message) {
      let updatedScreenState = {
        messages: [
          ...messages.map((x) => ({ ...x, active: false })),
          userMessage,
          { ...message, active: true },
        ],
      };

      if (
        safeJSONParse(message.content, { catch: () => null })?.command !==
        "conversation"
      ) {
        updatedScreenState = {
          ...updatedScreenState,
          selectedAiMessage: message,
        };
      }

      setScreenState(updatedScreenState);
    }
  };

  async aiResponse() {
    try {
      this.setState({ loading: true, error: null });
      const res = await api.post("v1/openai/multi-prompt", {
        sessionId: this.props.session._id,
        userMessage: this.props.inputValue,
      });
      console.log("ai res", { res });

      const message = res?.choice?.message;

      // if (res?.json) {

      //   this.props.dom.addNode("ROOT", json, 0);
      // }

      if (this.props.onAiCompletion) this.props.onAiCompletion(res);
      this.setState({ loading: false });

      return message;
    } catch (e) {
      this.setState({ loading: false, error: e.message });
      toast.error(e.message);
    }
  }

  // simulateAiResponse = async (userMsg, conversationHistory) => {
  //   const lastAssistantMsg = conversationHistory.find(
  //     (msg) => msg.role === "assistant"
  //   );
  //   let responseText = "";

  //   if (
  //     lastAssistantMsg &&
  //     lastAssistantMsg.content.includes("Is this scope fine")
  //   ) {
  //     responseText =
  //       "Great! I'll start creating the screens now. Now, it's time to build the logic. Should I proceed with making logic for the Home Screen?";
  //   } else if (
  //     lastAssistantMsg &&
  //     lastAssistantMsg.content.includes(
  //       "Should I proceed with making logic for the Home Screen"
  //     )
  //   ) {
  //     responseText =
  //       "Okay, I'm creating the Home Screen logic. Once done, some functions might not work until the other screens are ready. Next, I'll work on the Login Screen. Should I continue?";
  //   } else {
  //     responseText =
  //       "Thank you for your response. Let's continue with the process.";
  //   }

  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve({ role: "assistant", content: responseText });
  //     }, 1000);
  //   });
  // };

  render() {
    const { messages, inputValue, setScreenState } = this.props;

    return (
      <div style={styles.chatContainer}>
        <div style={styles.messagesContainer}>
          {messages.map((msg, index) => (
            <MessageRenderer key={index} {...this.props} msg={msg} />
          ))}
          <div ref={this.messagesEndRef} />
        </div>
        <div className="errormsg">{this.state.error}</div>
        <form onSubmit={this.handleSendMessage} style={styles.form}>
          <textarea
            type="text"
            value={inputValue}
            onChange={(e) => setScreenState({ inputValue: e.target.value })}
            placeholder="Type your message..."
            style={styles.input}
          />
          <button type="submit" style={styles.button}>
            {this.state.loading ? "Loading" : "Send"}
          </button>
        </form>
      </div>
    );
  }
}

const MessageRenderer = ({ msg, setScreenState, ...props }) => {
  const { content, role } = msg;

  const jsonContent = safeJSONParse(content, { catch: () => null });
  const command = jsonContent?.command || "conversation";
  const conversation = jsonContent ? jsonContent?.data?.text || "" : content;

  return (
    <div
      style={role === "user" ? styles.userMessage : styles.assistantMessage}
      onClick={() => {
        console.log({ msg, jsonContent, command, conversation });
        if (command !== "conversation")
          setScreenState({ selectedAiMessage: msg });
      }}
    >
      {command === "conversation" ? (
        <span style={styles.pre}>{conversation}</span>
      ) : (
        <span>
          <ActionRenderer {...props} msg={msg} jsonContent={jsonContent} />
        </span>
      )}
    </div>
  );
};

const ActionRenderer = (props) => {
  const { msg, jsonContent, project } = props;
  const { command, data = {} } = jsonContent || {};
  const active = msg.active;

  const [status, setStatus] = useState("");

  const executeCommand = async (command, data) => {
    try {
      setStatus("processing");
      if (command === "create_screen") {
        if (data?.screens?.length) {
          console.log({ command, data });

          const res = await api.post("v1/component/many", {
            project: project._id,
            components: data.screens.map((item) => {
              const domTreeNode = new DomTreeNode(
                "ROOT",
                { elementType: "container" },
                [aiModule.processUiMarkup(item.uiMarkup)]
              );

              const domTree = new DomTree(domTreeNode, () => {});

              return {
                name: item?.screenName,
                description: item?.description,
                data: {
                  url: item?.url,
                  dom: domTree.tree,
                },
              };
            }),
          });

          console.log({ res });
        }
      } else {
        await etcModule.sleep(2000);
      }
      setStatus("done");
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    if (active && command !== "conversation") {
      executeCommand(command, data);
    }
  }, [active]);

  // Generic condition: If any key in data is an array, map over that array.
  const arrayKey = Object.keys(data).find((key) => Array.isArray(data[key]));

  const renderCommandBox = (item, index) => {
    return <CommandBox key={index} {...{ item, index, command, status }} />;
  };

  if (arrayKey) {
    return (
      <div
        style={styles.genericContainer}
        // onClick={() => executeCommand(command, data)}
      >
        {data[arrayKey].map(renderCommandBox)}
      </div>
    );
  } else {
    // Otherwise, treat data as an object or string.
    return renderCommandBox(data, 0);
  }
};

const CommandBox = ({ item, index, command, status }) => {
  // Use Lodash to convert command name to a friendly title, e.g., "create_screen" -> "Create Screen"
  const commandDisplayName = _.startCase(command);

  // Helper: Extract a display name from an object.
  const extractDisplayName = (obj) => {
    if (typeof obj === "string") return obj;
    if (obj.screenName) return obj.screenName;
    if (obj.tableName) return obj.tableName;
    if (obj.columnName) return obj.columnName;
    if (obj.columnNames && Array.isArray(obj.columnNames))
      return obj.columnNames.join(", ");
    return "";
  };

  return (
    <div key={index} style={styles.genericItem}>
      <b>{commandDisplayName}</b> {extractDisplayName(item)}
      {status === "processing" ? (
        <div className="loader" />
      ) : status === "done" ? (
        <span style={{ color: "green" }}>&#x2714;</span>
      ) : null}
    </div>
  );
};

const styles = {
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    height: "80vh",
    width: "100%",
    margin: "20px auto",
    border: "1px solid #ccc",
    borderRadius: "8px",
    overflow: "hidden",
    fontFamily: "Arial, sans-serif",
  },
  messagesContainer: {
    flex: 1,
    padding: "10px",
    overflowY: "auto",
    backgroundColor: "#f9f9f9",
    display: "flex",
    flexDirection: "column",
  },
  userMessage: {
    alignSelf: "flex-end",
    background: "#DCF8C6",
    borderRadius: "10px",
    padding: "8px",
    margin: "6px 0",
    maxWidth: "90%",
    whiteSpace: "pre-wrap",
  },
  assistantMessage: {
    alignSelf: "flex-start",
    background: "#fff",
    borderRadius: "10px",
    padding: "8px",
    margin: "6px 0",
    maxWidth: "90%",
    whiteSpace: "pre-wrap",
    border: "1px solid #ddd",
  },
  pre: {
    margin: 0,
    fontFamily: "inherit",
  },
  form: {
    display: "flex",
    borderTop: "1px solid #ccc",
  },
  input: {
    flex: 1,
    padding: "12px",
    border: "none",
    outline: "none",
    fontSize: "16px",
  },
  button: {
    padding: "12px 20px",
    border: "none",
    background: "#007bff",
    color: "#fff",
    cursor: "pointer",
    fontSize: "16px",
  },
  codeBlock: {
    backgroundColor: "#f4f4f4",
    padding: "10px",
    borderRadius: "4px",
    overflowX: "auto",
    fontFamily: "monospace",
    cursor: "pointer",
    maxHeight: "250px",
  },
  selectedCodeBlock: {
    backgroundColor: "#e0e0e0",
    padding: "10px",
    borderRadius: "4px",
    overflowX: "auto",
    fontFamily: "monospace",
    cursor: "pointer",
    border: "2px solid #007bff",
    maxHeight: "250px",
  },
  genericContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    margin: "8px 0",
  },
  genericItem: {
    padding: "10px",
    backgroundColor: "#E0F7FA",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    color: "#006064",
    transition: "transform 0.2s ease",
    cursor: "pointer",
    fontSize: "13px",
    display: "flex",
    gap: "3px",
    alignItems: "center",
  },
};

const mapStateToProps = (state) => ({
  project: state.vState.BUILDER.project,
  databases: state.vState.BUILDER.databases,
  components: state.vState.BUILDER.components,
  allBuilderData: state.vState.BUILDER,
  canvasConfig: state.vState.BUILDER.canvasConfig || {},
  session: state.vState.AI_CHAT_INTERFACE.session || [],
  messages: state.vState.AI_CHAT_INTERFACE.messages || [],
  inputValue: state.vState.AI_CHAT_INTERFACE.inputValue || "",
  selectedAiMessage: state.vState.AI_CHAT_INTERFACE.selectedAiMessage,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "AI_CHAT_INTERFACE") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatInterface);
